import React from 'react';
import './App.css';
import RevenueChart from './components/RevenueChart';

function App() {
    const userIds = ["2107475719", "249864664"]; // Add more user IDs as needed

    return (
        <div className="App">
            <header className="header">
                <h1>Multi-user Revenue Dashboard</h1>
            </header>
            <main className="main-content">
                <div className="container">
                    <RevenueChart userIds={userIds} />
                </div>
            </main>
           
        </div>
    );
}

export default App;
