import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import './RevenueChart.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const RevenueChart = ({ userIds }) => {
    const [chartData, setChartData] = useState({});
    const [userStats, setUserStats] = useState({});
    const [conversionRate, setConversionRate] = useState(0);

    useEffect(() => {
        // Fetch USD to INR conversion rate
        const fetchConversionRate = async () => {
            try {
                const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
                setConversionRate(response.data.rates.INR);
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            }
        };

        fetchConversionRate();

        const fetchData = async () => {
            try {
                const promises = userIds.map(userId =>
                    axios.get(`https://terachart.api.websafe.in/revenue/${userId}`)
                );
                const responses = await Promise.all(promises);

                // Prepare data for chart and statistics
                const datasets = [];
                const stats = {};

                responses.forEach((response, index) => {
                    const data = response.data;

                    // Create a map to store amounts by date
                    const dateMap = {};

                    // Map amounts to dates for current user
                    data.forEach(record => {
                        const { date, amount } = record;
                        if (!dateMap[date]) {
                            dateMap[date] = [amount];
                        } else {
                            dateMap[date].push(amount);
                        }
                    });
                    const names = ['First Tera', 'Second Tera'];
                    const colours = ['#3e3ff8', '#bd093d'];

                    // Prepare datasets for Chart.js
                    const dataset = {
                        label: ` ${names[index]} Revenue`,
                        data: [],
                        borderColor: colours[index],
                        backgroundColor: colours[index],
                        fill: false,
                        xAxisID: 'x-axis-1', // Ensure different users use different x-axis
                    };

                    // Fill dataset with amounts corresponding to dates
                    Object.keys(dateMap).forEach(date => {
                        const totalAmount = dateMap[date].reduce((acc, curr) => acc + curr, 0);
                        dataset.data.push({ date, totalAmount });
                    });

                    datasets.push(dataset);

                    // Calculate statistics for the current user
                    const currentMonth = new Date().getMonth() + 1;
                    const currentYear = new Date().getFullYear();
                    const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
                    const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;

                    const startOfWeek = new Date();
                    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
                    const endOfWeek = new Date(startOfWeek);
                    endOfWeek.setDate(startOfWeek.getDate() + 6);

                    const currentMonthData = data.filter(record => {
                        const recordDate = new Date(record.date);
                        return recordDate.getMonth() + 1 === currentMonth && recordDate.getFullYear() === currentYear;
                    });

                    const lastMonthData = data.filter(record => {
                        const recordDate = new Date(record.date);
                        return recordDate.getMonth() + 1 === lastMonth && recordDate.getFullYear() === lastMonthYear;
                    });

                    const currentWeekData = data.filter(record => {
                        const recordDate = new Date(record.date);
                        return recordDate >= startOfWeek && recordDate <= endOfWeek;
                    });

                    const currentMonthTotal = currentMonthData.reduce((acc, record) => acc + record.amount, 0);
                    const lastMonthTotal = lastMonthData.reduce((acc, record) => acc + record.amount, 0);
                    const baseRevenue = data.reduce((acc, record) => acc + record.amount, 0) / data.length;
                    const minRevenueCurrentMonth = Math.min(...currentMonthData.map(record => record.amount));
                    const maxRevenueCurrentMonth = Math.max(...currentMonthData.map(record => record.amount));
                    const minRevenueCurrentWeek = Math.min(...currentWeekData.map(record => record.amount));
                    const maxRevenueCurrentWeek = Math.max(...currentWeekData.map(record => record.amount));

                    // Calculate revenue variation based on the last day
                    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
                    const lastRecord = sortedData[sortedData.length - 1];
                    const secondLastRecord = sortedData[sortedData.length - 2];
                    const revenueVariationLastDay = lastRecord.amount - (secondLastRecord ? secondLastRecord.amount : 0);

                    // Calculate standard deviation for volatility
                    const mean = data.reduce((acc, record) => acc + record.amount, 0) / data.length;
                    const variance = data.reduce((acc, record) => acc + Math.pow(record.amount - mean, 2), 0) / data.length;
                    const standardDeviation = Math.sqrt(variance);

                    let volatility;
                    if (standardDeviation > 20) {
                        volatility = "High";
                    } else if (standardDeviation > 10) {
                        volatility = "Medium";
                    } else if (standardDeviation > 5) {
                        volatility = "Low";
                    } else {
                        volatility = "Stable";
                    }

                    stats[userIds[index]] = {
                        currentMonthTotal,
                        lastMonthTotal,
                        baseRevenue,
                        minRevenueCurrentMonth,
                        maxRevenueCurrentMonth,
                        minRevenueCurrentWeek,
                        maxRevenueCurrentWeek,
                        revenueVariationLastDay,
                        volatility,
                        latestRevenue: lastRecord.amount,
                    };
                });

                // Combine all dates into a single array to find unique dates
                const allDates = responses.reduce((accumulator, response) => {
                    const dates = response.data.map(record => record.date);
                    return [...accumulator, ...dates];
                }, []);

                // Find unique dates and sort them chronologically
                const uniqueDates = [...new Set(allDates)].sort();

                // Create labels (dates) for Chart.js based on unique dates
                setChartData({
                    labels: uniqueDates,
                    datasets: datasets,
                });

                setUserStats(stats);
            } catch (error) {
                console.error('Error fetching revenue data:', error);
            }
        };

        fetchData();
    }, [userIds]);

    const formatAmount = (amount) => {
        const inrAmount = amount * conversionRate;
        return `${amount.toFixed(2)} USD (${inrAmount.toFixed(2)} INR)`;
    };

    return (
        <div>
            <div className="stats-container">
                <div className="stats left">
                    <h3>First Tera</h3>
                    {userStats[userIds[0]] && (
                        <>
                            <p>
                                Current Month Revenue: {formatAmount(userStats[userIds[0]].currentMonthTotal)}
                            </p>
                            <p>
                                Last Month Revenue: {formatAmount(userStats[userIds[0]].lastMonthTotal)}
                            </p>
                            <p>
                                Base Revenue: {formatAmount(userStats[userIds[0]].baseRevenue)}
                            </p>
                            <p>
                                Min Revenue (Current Month): {formatAmount(userStats[userIds[0]].minRevenueCurrentMonth)}
                            </p>
                            <p>
                                Max Revenue (Current Month): {formatAmount(userStats[userIds[0]].maxRevenueCurrentMonth)}
                            </p>
                            <p>
                                Min Revenue (Current Week): {formatAmount(userStats[userIds[0]].minRevenueCurrentWeek)}
                            </p>
                            <p>
                                Max Revenue (Current Week): {formatAmount(userStats[userIds[0]].maxRevenueCurrentWeek)}
                            </p>
                            <p>
                                Revenue Variation (Last Day): {formatAmount(userStats[userIds[0]].revenueVariationLastDay)}
                            </p>
                            <p>
                                Latest Revenue: {formatAmount(userStats[userIds[0]].latestRevenue)}
                            </p>
                            <p className={getClassnameForVolatility(userStats[userIds[0]].volatility)}>
                                Volatility: {userStats[userIds[0]].volatility}
                            </p>
                        </>
                    )}
                </div>
                <div className="stats right">
                    <h3>Second Tera</h3>
                    {userStats[userIds[1]] && (
                        <>
                            <p>
                                Current Month Revenue: {formatAmount(userStats[userIds[1]].currentMonthTotal)}
                            </p>
                            <p>
                                Last Month Revenue: {formatAmount(userStats[userIds[1]].lastMonthTotal)}
                            </p>
                            <p>
                                Base Revenue: {formatAmount(userStats[userIds[1]].baseRevenue)}
                            </p>
                            <p>
                                Min Revenue (Current Month): {formatAmount(userStats[userIds[1]].minRevenueCurrentMonth)}
                            </p>
                            <p>
                                Max Revenue (Current Month): {formatAmount(userStats[userIds[1]].maxRevenueCurrentMonth)}
                            </p>
                            <p>
                                Min Revenue (Current Week): {formatAmount(userStats[userIds[1]].minRevenueCurrentWeek)}
                            </p>
                            <p>
                                Max Revenue (Current Week): {formatAmount(userStats[userIds[1]].maxRevenueCurrentWeek)}
                            </p>
                            <p>
                                Revenue Variation (Last Day): {formatAmount(userStats[userIds[1]].revenueVariationLastDay)}
                            </p>
                            <p>
                                Latest Revenue: {formatAmount(userStats[userIds[1]].latestRevenue)}
                            </p>
                            <p className={getClassnameForVolatility(userStats[userIds[1]].volatility)}>
                                Volatility: {userStats[userIds[1]].volatility}
                            </p>
                        </>
                    )}
                </div>
            </div>
            <hr></hr>
            <div className="chart-container">
                <h2>Users Revenue Comparison Chart</h2>
                {chartData.labels ? (
                    <div className="chart">
                        <Line
                            data={{
                                labels: chartData.labels,
                                datasets: chartData.datasets.map(dataset => ({
                                    ...dataset,
                                    data: chartData.labels.map(label => {
                                        const dataPoint = dataset.data.find(data => data.date === label);
                                        return dataPoint ? dataPoint.totalAmount : null;
                                    }),
                                })),
                            }}
                        />
                    </div>
                ) : (
                    <p>Loading data...</p>
                )}
            </div>
        </div>
    );
};

// Function to determine CSS class based on volatility level
const getClassnameForVolatility = volatility => {
    switch (volatility) {
        case 'Stable':
        case 'Low':
            return 'green-text';
        case 'Medium':
            return 'orange-text';
        case 'High':
            return 'red-text';
        default:
            return '';
    }
};

export default RevenueChart;
